import React from 'react'
import { graphql, StaticQuery } from 'gatsby';
import ContactComponent from '../../../components/contact/contactComponent';

function ContactTemplate({ cmsData }) {
  
  const contactData = cmsData?.contact?.edges[0]?.node?.frontmatter ?? {}

  return (
      <div>
          <ContactComponent cmsData={contactData}/>
      </div>
  )
}

export default function Home() {
    return (
      <StaticQuery
        query={graphql`
          query {
            contact: allMarkdownRemark(filter: 
              {frontmatter: {templateKey: {eq: "en-contact"}}}) {
              edges {
                node {
                  frontmatter {
                    title
                    bg {
                      childImageSharp {
                        fluid(maxWidth: 2048, quality: 100) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                    eee
                    emailForm {
                      nameField
                      emailField
                      phoneField
                      messageField
                      buttonText
                    }
                  }
                }
              }
            },
          }
        `}
        render={(data) => <ContactTemplate cmsData={data} />}
      />
    );
  }
  